import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideo from 'components/Motion_Detection/Router_as_a_FTP_Server/WD_MyCloud_as_FTP_Server/YoutubeVideoCardWDNAS';
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/Router_as_a_FTP_Server/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Western Digital MyCloud as FTP Server",
  "path": "/Motion_Detection/Router_as_a_FTP_Server/WD_MyCloud_as_FTP_Server/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Use the FTP service of your router or network drive to back up your alarm video and images.",
  "image": "./MD_SearchThumb_RouterAsFTPServer_WD.png",
  "social": "/images/Search/MD_SearchThumb_RouterAsFTPServer_WD.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-Router-FTP-Server_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Western Digital MyCloud as FTP Server' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the FTP service of your router or network drive to back up your alarm video and images.' image='/images/Search/MD_SearchThumb_RouterAsFTPServer_WD.png' twitter='/images/Search/MD_SearchThumb_RouterAsFTPServer_WD.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/Router_als_FTP_Server/WD_MyCloud_als_FTP_Server/' locationFR='/fr/Motion_Detection/Router_as_a_FTP_Server/WD_MyCloud_as_FTP_Server/' crumbLabel="MyCloud" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "local-ftp-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#local-ftp-server",
        "aria-label": "local ftp server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Local FTP Server`}</h2>
    <h2 {...{
      "id": "setting-up-ftp-access-on-a-western-digital-wd-mycloud",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setting-up-ftp-access-on-a-western-digital-wd-mycloud",
        "aria-label": "setting up ftp access on a western digital wd mycloud permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up FTP Access on a Western Digital (WD) MyCloud`}</h2>
    <YoutubeVideo mdxType="YoutubeVideo" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/be460/WDMyCloud1_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABlElEQVQoz32SX2/TMBRH+/X3AuIb8MjEAxIP05gqpAkJbbRjG2xUkxCkbarQVE2bf4597eSguGvXtYWHIye69vH93aQTTCKuf04JogWjWcbdKOF3+xynjKZLxnFKOMs84zhjEicsFglpmnqiKGI4HBKGIeEkpONcjXPOY60jThWlUpt3a+0e6/2H6IgI2zT1qmCMoSxLlFJ+zbKMPM/Ji8LXds+t2Qjbm3eLbafGCKqqyLIcXSlEV154aL8XbkfZlreHfEd5zjLN0EVGL0w5/rGg0hq3I1w7Ovuyp03GaMTHM1gRksIwWWpEnl9+ULgtbmdYuxqlhVlakOQKLSXOpliZY6SgrsHKk0z+3aFFqZzeQ0D36z3dmwEn/TtOrz7z6f6c7u0ZXwbvGf+5QazsxX4mbOMqsbz7FvDy7JoXH/ocnV5xdHLJq+4tb3u/OL4c8PrjBW8u+nyfLv2v9R/hap0ry7xyDHPHYF4RLDULXUNTAw2FNBQGH3Mls569Ga6ljRMPLbX11I/xTDtju8JuPoY8YvkLz9BM7sza7tkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/e4706/WDMyCloud1_EN.avif 230w", "/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/d1af7/WDMyCloud1_EN.avif 460w", "/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/7f308/WDMyCloud1_EN.avif 920w", "/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/e1c99/WDMyCloud1_EN.avif 1380w", "/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/164db/WDMyCloud1_EN.avif 1645w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/a0b58/WDMyCloud1_EN.webp 230w", "/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/bc10c/WDMyCloud1_EN.webp 460w", "/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/966d8/WDMyCloud1_EN.webp 920w", "/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/445df/WDMyCloud1_EN.webp 1380w", "/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/02cd3/WDMyCloud1_EN.webp 1645w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/81c8e/WDMyCloud1_EN.png 230w", "/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/08a84/WDMyCloud1_EN.png 460w", "/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/c0255/WDMyCloud1_EN.png 920w", "/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/b1001/WDMyCloud1_EN.png 1380w", "/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/be460/WDMyCloud1_EN.png 1645w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e0e38cad4ccf94e92cf11a05116b9b2d/c0255/WDMyCloud1_EN.png",
              "alt": "Western Digital MyCloud as FTP Server",
              "title": "Western Digital MyCloud as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Open the WD MyCloud in your web browser by typing `}<a parentName="p" {...{
        "href": "http://wdmycloud.local"
      }}>{`http://wdmycloud.local`}</a>{` in the address bar of your web browser. The login to your WD MyCloud should open. Please log in with your admin account.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/cea38/WDMyCloud2_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB10lEQVQoz22RW2/TMBiG+8MRMKGVv8DFNIkbGIghIXHBDXcTiHFYTyxdN22wJUvSxHZsd2ritHlQPBhdN0uv3k+f/T4+dZ4PYrb7CS8CxcuJYffE8v5sxs7EsHOkvb86tl5vTgyvjxS7k4J3p5a3x5rtg5hn++dsfY/Y+hHR6QbQDRq648b75s+aB72Kx4OSh/2SjWHFo37p9WRYsdGb0x1VPB3O6U5g8wS6h4trRtDQAcctzS3aXnl3RtJcFVRFxtJKFlbitKCZKar5DA6/QTjBj6b0+U7palZVGMt5GBImCabQSKl8T9sZotDeVVEgr+aYUY/qwy6ltZT1grJydJyrcc55Ncslvy8u2Puyz8e9TwTjAUkSIaTCGuNBIs+JpzlhdEkxr6itoQ5/4ZqG2jk6df0f2I7x8YSD4YivBz0+D/pkSiGlZDazZFnO6ekZQkxJ0gQlJfVigVsurxl1fRvY1lprhsEho3FAlucIIX3PWotSijRN0YVECOH7PvcXdueEbd1CLuOYLMv8+6XTjPAyxhjDNMu9t1ePkxStjc/8Y9T3nTAXgiiKuIgiojTzb9eC2nDRfoZSHtqu08bc5O4AV31V63Orm6/XN8D7Atdan7u70Xr9Bz2pOlk6iV21AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/e4706/WDMyCloud2_EN.avif 230w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/d1af7/WDMyCloud2_EN.avif 460w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/7f308/WDMyCloud2_EN.avif 920w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/e1c99/WDMyCloud2_EN.avif 1380w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/31f45/WDMyCloud2_EN.avif 1644w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/a0b58/WDMyCloud2_EN.webp 230w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/bc10c/WDMyCloud2_EN.webp 460w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/966d8/WDMyCloud2_EN.webp 920w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/445df/WDMyCloud2_EN.webp 1380w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/47b01/WDMyCloud2_EN.webp 1644w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/81c8e/WDMyCloud2_EN.png 230w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/08a84/WDMyCloud2_EN.png 460w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/c0255/WDMyCloud2_EN.png 920w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/b1001/WDMyCloud2_EN.png 1380w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/cea38/WDMyCloud2_EN.png 1644w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/c0255/WDMyCloud2_EN.png",
              "alt": "Western Digital MyCloud as FTP Server",
              "title": "Western Digital MyCloud as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: In the upper menu please click on the arrow to the right side and select „Settings“.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/70018652a0eaf1613e3a24f935e3ce44/cea38/WDMyCloud3_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABw0lEQVQoz42RbUtUQRiGz3+3gmyDIDKEPpQathD9gAgK6ktUmrou5+haEWJmu+dt3mfOOVecsZVVFPpw8wzPyzX3M5M82Tlldfs3z8YFa6lgeCh4eaRYS2vW04r1rGbjQLCRCTYPBM/Tks2s4tWR4MWhYHX7lIcff/Lo8wlPt45JBhkMsi7qXtZxf+xZHhnujBxLuzbGW7s26u7IcntHM9i3LO05lsf+YnY5g5XUkoCHzp2rtXhRYKocdEUjCzpV4qsZrcgvzn2tqCVYSZyPCuAUiXUe27RYiNJVEQu0AWUsUhucD7jQYBdiCCHm+3Nk+IBQmsQ3Db4qaA5GuA9vGE4UD/Y1b08sWgrKWmCMQUqFdw7v/SX14HnUOgJbfJnTTMa4T+95/a1kOJFsnRmMVhjrKIqSaVmfLxYC4Z/DOewSMPSJtp2/ArqY0tZTOiORSqO0jmv76Snd8ff/cDhPOBc1zQvOZjnaGIqy4s8sR0rB3uMVfnz5Ste1NwKVUiTXWZ9LyH5lS5nnvMt+kc5M/KzFvsU5a+058LqGXn2Dcy66ba2i8xbvw40O+5gsOrp6Y1XX5EVJWVXRbQ/W/XsuAK6C/wLOPUC1Jhp4iQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/70018652a0eaf1613e3a24f935e3ce44/e4706/WDMyCloud3_EN.avif 230w", "/en/static/70018652a0eaf1613e3a24f935e3ce44/d1af7/WDMyCloud3_EN.avif 460w", "/en/static/70018652a0eaf1613e3a24f935e3ce44/7f308/WDMyCloud3_EN.avif 920w", "/en/static/70018652a0eaf1613e3a24f935e3ce44/e1c99/WDMyCloud3_EN.avif 1380w", "/en/static/70018652a0eaf1613e3a24f935e3ce44/31f45/WDMyCloud3_EN.avif 1644w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/70018652a0eaf1613e3a24f935e3ce44/a0b58/WDMyCloud3_EN.webp 230w", "/en/static/70018652a0eaf1613e3a24f935e3ce44/bc10c/WDMyCloud3_EN.webp 460w", "/en/static/70018652a0eaf1613e3a24f935e3ce44/966d8/WDMyCloud3_EN.webp 920w", "/en/static/70018652a0eaf1613e3a24f935e3ce44/445df/WDMyCloud3_EN.webp 1380w", "/en/static/70018652a0eaf1613e3a24f935e3ce44/47b01/WDMyCloud3_EN.webp 1644w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/70018652a0eaf1613e3a24f935e3ce44/81c8e/WDMyCloud3_EN.png 230w", "/en/static/70018652a0eaf1613e3a24f935e3ce44/08a84/WDMyCloud3_EN.png 460w", "/en/static/70018652a0eaf1613e3a24f935e3ce44/c0255/WDMyCloud3_EN.png 920w", "/en/static/70018652a0eaf1613e3a24f935e3ce44/b1001/WDMyCloud3_EN.png 1380w", "/en/static/70018652a0eaf1613e3a24f935e3ce44/cea38/WDMyCloud3_EN.png 1644w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/70018652a0eaf1613e3a24f935e3ce44/c0255/WDMyCloud3_EN.png",
              "alt": "Western Digital MyCloud as FTP Server",
              "title": "Western Digital MyCloud as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: On the left side please click on „Network“ and within the network services please switch the IPv4 Network Mode to „static“. This is important to make sure that the IP address of your MyCloud is not changing. If you are noting switching it to static, it could be that your router will assign a different IP address to your MyCloud. In this case the camera would not be able to reach it anymore and therefore will not record anymore to the MyCloud. Therefore this step is very important.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "541px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5b11b2d45099f376455dabc8c0afcd0b/9d576/WDMyCloud4_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "102.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABoElEQVQ4y72U63KCMBCFef8na2faahVBQFuxIlYlCQSQi+3p7Iq0dRyrztgfZzabbL6c5RJDSgUpJeI4RpIkEFIiEoJzpRSPKfJaM39KxiyY4+6hg05vgN5gCMvxWKY1hGk76PQtPHb76JoWun0L949dOKOX9sC9WiCdvNlsWpVlgaosURQFq2zG+SbnNarZz3Nt1aznOXdhZFmGNMug4gSRVBBSYS0kx0hQ+4ofwz6XipzFHEnL1RqJTrHdbpEkGgaR/bcATz0Lz6YNbzyBabsYumO441fY7hiWM9rFoQd39NrUOOjbLt6CEFqnqOv626GKYwTzBbukk37q41deH6zX+Pz84JqqqnbANE25ZbL+MpkifF9yMRVcotYhAckhgcLFEjrNsK3r64Faa54oipJFb69ugBT/0lHgsaJzYCeBh/avccgf9iHwXGf/B6S+bwq8VjcE6t3vdmmrx8RAFav2SqKL4lJlec57iSGVhCGkwMT3YdkO5mEIyiMRnS0hIkz8KcLFgm90Yx2t4U+ncD0PwTxg4CVarVa8fxYEfMAXTeoGD9SUgtIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b11b2d45099f376455dabc8c0afcd0b/e4706/WDMyCloud4_EN.avif 230w", "/en/static/5b11b2d45099f376455dabc8c0afcd0b/d1af7/WDMyCloud4_EN.avif 460w", "/en/static/5b11b2d45099f376455dabc8c0afcd0b/d3c66/WDMyCloud4_EN.avif 541w"],
              "sizes": "(max-width: 541px) 100vw, 541px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5b11b2d45099f376455dabc8c0afcd0b/a0b58/WDMyCloud4_EN.webp 230w", "/en/static/5b11b2d45099f376455dabc8c0afcd0b/bc10c/WDMyCloud4_EN.webp 460w", "/en/static/5b11b2d45099f376455dabc8c0afcd0b/fd5d3/WDMyCloud4_EN.webp 541w"],
              "sizes": "(max-width: 541px) 100vw, 541px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5b11b2d45099f376455dabc8c0afcd0b/81c8e/WDMyCloud4_EN.png 230w", "/en/static/5b11b2d45099f376455dabc8c0afcd0b/08a84/WDMyCloud4_EN.png 460w", "/en/static/5b11b2d45099f376455dabc8c0afcd0b/9d576/WDMyCloud4_EN.png 541w"],
              "sizes": "(max-width: 541px) 100vw, 541px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5b11b2d45099f376455dabc8c0afcd0b/9d576/WDMyCloud4_EN.png",
              "alt": "Western Digital MyCloud as FTP Server",
              "title": "Western Digital MyCloud as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 4`}</strong>{`: Follow the assistant to assign a static IP address. You actually only have to click on „Next“ and „Apply“ in order to apply the settings. Please remember the IP address shown in Step2. This IP address will be the one with which you can access your WD MyCloud at any time.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "541px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1aef938890467113222542e7267a837a/9d576/WDMyCloud5_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "101.73913043478262%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACdElEQVQ4y41U2W7bMBDU/39YH1IEQVM0TdEisW5bIsVbh21lil2JauK4aR8GuyKXw9lDTJRS0FrDGAOlNTql0HUdum5Zt9bCWsP7/4OkKCt8urnF0y7Dr+cUd/ff8PD4E18fHnFz+wWf7+5R1Qcmjhd/SOicQwgBfd9jGAaEvmefQOvOe14fx5HtNcR4ujTx3mOaJvS0OY44nU6M8/nM69vt1oJi40WvcTwe+QyJS2KQ0gad0pjWTQKtZ0XJODQt13ip6VvQxRS/KfQhQJmF0Dq/EVI6ZVWB6kykQkg+dFm3d4SULpESGdVwPp85ZSKs9wdGWdXceUrrnwqdD6zOOMdKW9nBh54bQUSsTsqrZFcJSSEVnToauOg9xmlihU3TLvVTimOu4R2hsY5VEUjt65R3aY68rGCtg7MGvbMIK7yzcJeElMY8z3iZZ7ZEFMeAbMTpeETtjvguRvyQIx7lYnMzYpiWeB7sqLARkhVqY990mRSmWYE0y7ErajTKoiVoC6EtpLZcno2QFA7DyF3m4R7GTSERtkLwuDSt4H88eLfBuwXTJSE1QyrN4BpSCV5m7rI1hrvr1nrFh4Js9EkAlWpLmUamqGoufppX7BN2WYH9oWG/3jf8SGRFxXNZlDX7tFYfGngf+AImbIXkw4Rnqlle4mlH3a3R98My8KFfht+69Sdw25jR97j+9wm1muTSqMRUz6sfux7ttn6xH8GE2uj1eRq214P8+M2vSx+WgX/j/7EUSxxKKySd6pAXJdK84C5KKbBLM2R5CdlJSCkX+xd0neT4er/n84mQAnlBc5ahFS2apmGfv9sWdOFHoBiKLcpFwG/8Hvl/7SVdzQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1aef938890467113222542e7267a837a/e4706/WDMyCloud5_EN.avif 230w", "/en/static/1aef938890467113222542e7267a837a/d1af7/WDMyCloud5_EN.avif 460w", "/en/static/1aef938890467113222542e7267a837a/d3c66/WDMyCloud5_EN.avif 541w"],
              "sizes": "(max-width: 541px) 100vw, 541px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1aef938890467113222542e7267a837a/a0b58/WDMyCloud5_EN.webp 230w", "/en/static/1aef938890467113222542e7267a837a/bc10c/WDMyCloud5_EN.webp 460w", "/en/static/1aef938890467113222542e7267a837a/fd5d3/WDMyCloud5_EN.webp 541w"],
              "sizes": "(max-width: 541px) 100vw, 541px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1aef938890467113222542e7267a837a/81c8e/WDMyCloud5_EN.png 230w", "/en/static/1aef938890467113222542e7267a837a/08a84/WDMyCloud5_EN.png 460w", "/en/static/1aef938890467113222542e7267a837a/9d576/WDMyCloud5_EN.png 541w"],
              "sizes": "(max-width: 541px) 100vw, 541px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1aef938890467113222542e7267a837a/9d576/WDMyCloud5_EN.png",
              "alt": "Western Digital MyCloud as FTP Server",
              "title": "Western Digital MyCloud as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "540px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/15e6baf7b1be4f6697789cf4d686f8ad/07484/WDMyCloud6_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "101.73913043478262%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACWklEQVQ4y6WUWXObQBCE+f9/LA95cMWO7Vg2RhISN8tyLwLRqR4kgixfVXno2hp2+aZn9rCyLINSChzT06iyDFprFEWBPM9nMX6r5RxHa+vu8OPnDZ6ebTw+PeP29yNubu9x9/Ak8GIB/I6ssizRtC0OhwO6rkPXGZExBp0xqOsaVV2jaZpZbduKzjHXMKZLy5gWznaHX/d/8LCycfe4wsp2cH8agzCC7WzxunHhByH2fgAviOCHMTbuDn4QSUwzNGc1TQ2tc4RRAp3nSJWGyjSSRCFVmTgfhgEYRxyPA/r+IN+ovu8vJA5ptyhKJCrDcRhwPB5F4ziNQ9+jrht4fiiJBNb3M3QJn4HsUV4UAlg6OC9kP1lF3TTitj8B+wV4BlZVJU1leVGSojXmaiGBmc6RZhp5Xkjcv3F5AeQOZVrPPRvFaX/lkNCyrGC67nOg9LGspHRuDH9umnZuNoG5HNxCkn9UspxDwrjdXhAiTnlTNHZeIG7O/WIbZOdThbY1nwPpkG4qAVeyozwihC1L5kngPN2/B70AspwwTuQQx0l6lZlJgjAW+WEkZY+LpFdAuTbsYVWje6cUOiyrCsZ0KKupEraErq8c1tU0ufdDuUJcPAz/gHTBRHsvkD7y6rEaxhyvgNwQXqnxdEP6D24B5wk/r5t0WTKfPAEus/yPdK5h0WZrpueobqZnii8QvzH+StN6g7ZtkOkMFh/R5xcbqxcbqUqRJAmc9QZ8eBl/pTiJYTtrOSF8+a0kJWCNV8cRWBSF2Lgutq4ri1WmPpZSCMMQ680Ge8+TBH8BypMDEqYefTIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/15e6baf7b1be4f6697789cf4d686f8ad/e4706/WDMyCloud6_EN.avif 230w", "/en/static/15e6baf7b1be4f6697789cf4d686f8ad/d1af7/WDMyCloud6_EN.avif 460w", "/en/static/15e6baf7b1be4f6697789cf4d686f8ad/b90cc/WDMyCloud6_EN.avif 540w"],
              "sizes": "(max-width: 540px) 100vw, 540px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/15e6baf7b1be4f6697789cf4d686f8ad/a0b58/WDMyCloud6_EN.webp 230w", "/en/static/15e6baf7b1be4f6697789cf4d686f8ad/bc10c/WDMyCloud6_EN.webp 460w", "/en/static/15e6baf7b1be4f6697789cf4d686f8ad/9e625/WDMyCloud6_EN.webp 540w"],
              "sizes": "(max-width: 540px) 100vw, 540px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/15e6baf7b1be4f6697789cf4d686f8ad/81c8e/WDMyCloud6_EN.png 230w", "/en/static/15e6baf7b1be4f6697789cf4d686f8ad/08a84/WDMyCloud6_EN.png 460w", "/en/static/15e6baf7b1be4f6697789cf4d686f8ad/07484/WDMyCloud6_EN.png 540w"],
              "sizes": "(max-width: 540px) 100vw, 540px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/15e6baf7b1be4f6697789cf4d686f8ad/07484/WDMyCloud6_EN.png",
              "alt": "Western Digital MyCloud as FTP Server",
              "title": "Western Digital MyCloud as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 5`}</strong>{`: You have to login again once the IP has been changed to a static IP. Please login in again. Once logged in, please click the right arrow again in the upper menu and click on „Settings“.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/cea38/WDMyCloud2_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB10lEQVQoz22RW2/TMBiG+8MRMKGVv8DFNIkbGIghIXHBDXcTiHFYTyxdN22wJUvSxHZsd2ritHlQPBhdN0uv3k+f/T4+dZ4PYrb7CS8CxcuJYffE8v5sxs7EsHOkvb86tl5vTgyvjxS7k4J3p5a3x5rtg5hn++dsfY/Y+hHR6QbQDRq648b75s+aB72Kx4OSh/2SjWHFo37p9WRYsdGb0x1VPB3O6U5g8wS6h4trRtDQAcctzS3aXnl3RtJcFVRFxtJKFlbitKCZKar5DA6/QTjBj6b0+U7palZVGMt5GBImCabQSKl8T9sZotDeVVEgr+aYUY/qwy6ltZT1grJydJyrcc55Ncslvy8u2Puyz8e9TwTjAUkSIaTCGuNBIs+JpzlhdEkxr6itoQ5/4ZqG2jk6df0f2I7x8YSD4YivBz0+D/pkSiGlZDazZFnO6ekZQkxJ0gQlJfVigVsurxl1fRvY1lprhsEho3FAlucIIX3PWotSijRN0YVECOH7PvcXdueEbd1CLuOYLMv8+6XTjPAyxhjDNMu9t1ePkxStjc/8Y9T3nTAXgiiKuIgiojTzb9eC2nDRfoZSHtqu08bc5O4AV31V63Orm6/XN8D7Atdan7u70Xr9Bz2pOlk6iV21AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/e4706/WDMyCloud2_EN.avif 230w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/d1af7/WDMyCloud2_EN.avif 460w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/7f308/WDMyCloud2_EN.avif 920w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/e1c99/WDMyCloud2_EN.avif 1380w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/31f45/WDMyCloud2_EN.avif 1644w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/a0b58/WDMyCloud2_EN.webp 230w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/bc10c/WDMyCloud2_EN.webp 460w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/966d8/WDMyCloud2_EN.webp 920w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/445df/WDMyCloud2_EN.webp 1380w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/47b01/WDMyCloud2_EN.webp 1644w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/81c8e/WDMyCloud2_EN.png 230w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/08a84/WDMyCloud2_EN.png 460w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/c0255/WDMyCloud2_EN.png 920w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/b1001/WDMyCloud2_EN.png 1380w", "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/cea38/WDMyCloud2_EN.png 1644w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/94ccf1e4797f1dfc5ab70bd5724ca12a/c0255/WDMyCloud2_EN.png",
              "alt": "Western Digital MyCloud as FTP Server",
              "title": "Western Digital MyCloud as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 6`}</strong>{`: Once again select „Network“ and within the network services please switch the FTP Access to „ON“. ON should then be shown in blue.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/bd44e/WDMyCloud7_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABxElEQVQoz32QTU8UQRCG52d7V2OQNTHG+MFFRIIQ4w8gHDjogRizAdyvgcUDBjCwOzM9Pf09s4+ZJmvWFT08qerqrrffqmTt8Iq14xvWh4L1kWT7VPLhTLExKtkcCd6OSt6lko1UsnUi2RwWbKWCj2eS9yeSl92fPDs45/nXS950L0g6KXTSWWQ1nfFk4FntGe5/c9w79DzqWR4c37LSszw80nT6Nt6vDPzv3scpvBhZEvAwc7c0Fi9zrMgIuqKQClSOF1MamTFTRczRgryswFbE/kgAr0is89i6wUJEixycgiZgrEVqg/MBF2rsQgwhxHqbRw0fkEqT+LrBi5z6tI/7vMf2WPG0b9i/tOhKUpQSYwyVUnjv/6IVnket54JFTj0e4r58Yvd7wc64onttMFpFl9NcMM2K2LTMonAUDG2haeZbQOcTmnLCzFRUSqO0pjYqurXW/iFyt8N5wbnIzTTnepqhjaEoBJNpxtFVxY/C4JzDuX+PrJQiuct6PIeArBTeaF4NNAe7+9Q7r/EX57RTzVnsaydIFn9YHqd94KJzixU5NpsQ2vV4d6fDNib/W7IoS7K8IC8EldYY7+OeFgWWhX8BmoVASq10G5cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/e4706/WDMyCloud7_EN.avif 230w", "/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/d1af7/WDMyCloud7_EN.avif 460w", "/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/7f308/WDMyCloud7_EN.avif 920w", "/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/e1c99/WDMyCloud7_EN.avif 1380w", "/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/82f54/WDMyCloud7_EN.avif 1643w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/a0b58/WDMyCloud7_EN.webp 230w", "/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/bc10c/WDMyCloud7_EN.webp 460w", "/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/966d8/WDMyCloud7_EN.webp 920w", "/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/445df/WDMyCloud7_EN.webp 1380w", "/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/654f6/WDMyCloud7_EN.webp 1643w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/81c8e/WDMyCloud7_EN.png 230w", "/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/08a84/WDMyCloud7_EN.png 460w", "/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/c0255/WDMyCloud7_EN.png 920w", "/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/b1001/WDMyCloud7_EN.png 1380w", "/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/bd44e/WDMyCloud7_EN.png 1643w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0f9d5b6bd7b22c923f7e5c04aa847c04/c0255/WDMyCloud7_EN.png",
              "alt": "Western Digital MyCloud as FTP Server",
              "title": "Western Digital MyCloud as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 7`}</strong>{`: The FTP Access in general is now activated. In order to tell the camera in which folder it should record, we will need to set up a shared Folder. Therefore please click on „Shares“ in the upper menu and inside this menu please click on the + sign in order to add a shared Folder.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ea36ba03525dd299060004b0d32f4716/bd44e/WDMyCloud8_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAByElEQVQoz4WSzYvTQBjG+3d73IMgrRdhURbE1QWL6MGDR+nNRRYVXbo2rUq1tNlNkzaZZPIxH8lPkm5qXFk8/HjhnXmeeeeZ6R19vuTo3Od4Ijh2EobfEl79SHnmxJzMJEMn4vWXFU9nKc9nCSeTiOFU8PK7ZDhLePjpigfvFxx+cBt6gykMplVDv64Tzf1xzsGk4s5oyYvBIw7PrjgYa+6Nc+6eZ/QvCgYXBf2vqtF06YGmS5UKitDHZJLID8CdkyWCKtlSyhAdbyET1zXmpr5XaENLrjShSPC3EYlMMdaS2bKpylisMZR1T2kKpVAdbUtPa4PWusEYgx9s8IOALMvw1gHznwtcbzfxOzfj47rAk4rHTkquDdbU2tbD0KtNuoZr32e5cpFJQhBs8fwNIo5RRc4mtyxFjhcK/LzEXOtqo1pbszdsq5QSIQR5UbAwsMghNxawvJmnjJYZiYh44kjCTFOaP8P8Zdg20zQljGMid4U6HZGslqSFprKaX7HmUu5ydEJNqkyT6z8T3pyyfpzU9yhP32K91T4nrG6o91C2ZrVO337ldsFW1e4jVKCbXhv+bl11bvXfCfcRqKIJvgm/3tc58DZ+A1oaQUYp0xQRAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ea36ba03525dd299060004b0d32f4716/e4706/WDMyCloud8_EN.avif 230w", "/en/static/ea36ba03525dd299060004b0d32f4716/d1af7/WDMyCloud8_EN.avif 460w", "/en/static/ea36ba03525dd299060004b0d32f4716/7f308/WDMyCloud8_EN.avif 920w", "/en/static/ea36ba03525dd299060004b0d32f4716/e1c99/WDMyCloud8_EN.avif 1380w", "/en/static/ea36ba03525dd299060004b0d32f4716/82f54/WDMyCloud8_EN.avif 1643w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ea36ba03525dd299060004b0d32f4716/a0b58/WDMyCloud8_EN.webp 230w", "/en/static/ea36ba03525dd299060004b0d32f4716/bc10c/WDMyCloud8_EN.webp 460w", "/en/static/ea36ba03525dd299060004b0d32f4716/966d8/WDMyCloud8_EN.webp 920w", "/en/static/ea36ba03525dd299060004b0d32f4716/445df/WDMyCloud8_EN.webp 1380w", "/en/static/ea36ba03525dd299060004b0d32f4716/654f6/WDMyCloud8_EN.webp 1643w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ea36ba03525dd299060004b0d32f4716/81c8e/WDMyCloud8_EN.png 230w", "/en/static/ea36ba03525dd299060004b0d32f4716/08a84/WDMyCloud8_EN.png 460w", "/en/static/ea36ba03525dd299060004b0d32f4716/c0255/WDMyCloud8_EN.png 920w", "/en/static/ea36ba03525dd299060004b0d32f4716/b1001/WDMyCloud8_EN.png 1380w", "/en/static/ea36ba03525dd299060004b0d32f4716/bd44e/WDMyCloud8_EN.png 1643w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ea36ba03525dd299060004b0d32f4716/c0255/WDMyCloud8_EN.png",
              "alt": "Western Digital MyCloud as FTP Server",
              "title": "Western Digital MyCloud as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 8`}</strong>{`: For Share Name you can now type in the folder name in which you want the recordings being stored. In our case we choose the folder name „Recordings“. You can also add a description to the folder name. In our case we name it „INSTAR Cameras“. Once you typed in the Share Name and Description please „Apply“ the settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "535px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/660d9d37b2b3c4dbc4aa0ce1a27fab44/b5245/WDMyCloud9_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABVElEQVQoz52R2U7DMBBF8/+fhgQUKlSJ0iTN1jiNHcexs1cXzbQJlAckeLi5ljNzPItX1zW01lCqAp2NMez/lXfKBR43W2xe3/Dw9IL3Dx/LI38VA+njXIuu6+CcY7Vtu6rv+5s6jvmpYRjYKZa685qmQc+X10QKmKaJNY4jamOgqR1j1geXR621EOeS8+Z5BrEY2FgLpWtUukZjHS6XmYEET9IMYRTjGCcMIFEllFdVFZ63O1jnGLhWSBe6NjAUpKmahqsjYJqdEMUJg49RglwUMOa6AALu/ZArvgPSDLUxMNayU8VLy6I4IzsJCFGwl1KubdMYdnsfrm2/gLQdSqQ2ycdhWAdNojue6c3Hcfi2KJrdtI6Ht1xVCrkQOAQhRFFAKQWpJPtyllLe+fKP/0uJIIyQ5wLE8kpZIklT+EGANEs5QFXqLuk35SLHwQ8QxwmI9QkVmfsk7hgD/QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/660d9d37b2b3c4dbc4aa0ce1a27fab44/e4706/WDMyCloud9_EN.avif 230w", "/en/static/660d9d37b2b3c4dbc4aa0ce1a27fab44/d1af7/WDMyCloud9_EN.avif 460w", "/en/static/660d9d37b2b3c4dbc4aa0ce1a27fab44/dc608/WDMyCloud9_EN.avif 535w"],
              "sizes": "(max-width: 535px) 100vw, 535px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/660d9d37b2b3c4dbc4aa0ce1a27fab44/a0b58/WDMyCloud9_EN.webp 230w", "/en/static/660d9d37b2b3c4dbc4aa0ce1a27fab44/bc10c/WDMyCloud9_EN.webp 460w", "/en/static/660d9d37b2b3c4dbc4aa0ce1a27fab44/e0c9f/WDMyCloud9_EN.webp 535w"],
              "sizes": "(max-width: 535px) 100vw, 535px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/660d9d37b2b3c4dbc4aa0ce1a27fab44/81c8e/WDMyCloud9_EN.png 230w", "/en/static/660d9d37b2b3c4dbc4aa0ce1a27fab44/08a84/WDMyCloud9_EN.png 460w", "/en/static/660d9d37b2b3c4dbc4aa0ce1a27fab44/b5245/WDMyCloud9_EN.png 535w"],
              "sizes": "(max-width: 535px) 100vw, 535px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/660d9d37b2b3c4dbc4aa0ce1a27fab44/b5245/WDMyCloud9_EN.png",
              "alt": "Western Digital MyCloud as FTP Server",
              "title": "Western Digital MyCloud as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 9`}</strong>{`: The newly created shared Folder should now already be selected. In order to be able to access it by FTP, you will need to activate the FTP access. Please activate it. Now you still need to choose if a anonymous user can access the folder or not, in this case we recommend you to select Anonymous None“ which means that no one can access the recordings besides you. Once done please click „Apply“.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/aaed1826982279a08e13e2ac8311e597/cea38/WDMyCloud10_EN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABzklEQVQoz3WSS08UQRSF50+7lxiD6NLEaIwuDAIZTUh05YaVJmJUREB7GBQi4ozOQHfXu241fKaLhx0DlZycStU95z6qeo8+jXi8MWG+qJgfKJ7vaJa/GxYGiqVBzdOBYnFbs7Ct6Q81i0VFf7tm+Zvm2VDzcG3MvdUf3P/wiydrB/TmCpgrTjJuFSfc/iLMbQaubwjX1oSbG5GZz4GZ9cDsZuDGusv3GVuSNa12toC7RaQHQhfHpiJWU5LVlNqCKZH6kGN9xImp8h5XE1sOuqNNII5ekMQ5fBSmpeL3UYWyDokR4wMhNcSMhKQmx8aOLkTJbKyjJ5IQEVI6ZXU4IbQVBUdZK5TSiLNYa7DWEkK4iO3qWnbO0eseRBFe70558fUPWxOHd6cGq3uH7OyPsMYQYvxncoYrDYMI/d3Ana3Aq5+R6G1OMhgrDrTHGUPjPdIxlMsq7GaJpsaVE6JRGGvRSpHXxzc0Ky8vDOTM7PIKO1msD9TaYJ2nUhqrNW/HkeG795jdYX557QNub4fjpQek0T7SNJe3fM5N09A06WJetXaUXvDtS4eQz6N3pOroipb/Mz3f10pRVjUpCak1CYEYY/5O7WxjW1kn3nvPX0JZQD9XvXowAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aaed1826982279a08e13e2ac8311e597/e4706/WDMyCloud10_EN.avif 230w", "/en/static/aaed1826982279a08e13e2ac8311e597/d1af7/WDMyCloud10_EN.avif 460w", "/en/static/aaed1826982279a08e13e2ac8311e597/7f308/WDMyCloud10_EN.avif 920w", "/en/static/aaed1826982279a08e13e2ac8311e597/e1c99/WDMyCloud10_EN.avif 1380w", "/en/static/aaed1826982279a08e13e2ac8311e597/31f45/WDMyCloud10_EN.avif 1644w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/aaed1826982279a08e13e2ac8311e597/a0b58/WDMyCloud10_EN.webp 230w", "/en/static/aaed1826982279a08e13e2ac8311e597/bc10c/WDMyCloud10_EN.webp 460w", "/en/static/aaed1826982279a08e13e2ac8311e597/966d8/WDMyCloud10_EN.webp 920w", "/en/static/aaed1826982279a08e13e2ac8311e597/445df/WDMyCloud10_EN.webp 1380w", "/en/static/aaed1826982279a08e13e2ac8311e597/47b01/WDMyCloud10_EN.webp 1644w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aaed1826982279a08e13e2ac8311e597/81c8e/WDMyCloud10_EN.png 230w", "/en/static/aaed1826982279a08e13e2ac8311e597/08a84/WDMyCloud10_EN.png 460w", "/en/static/aaed1826982279a08e13e2ac8311e597/c0255/WDMyCloud10_EN.png 920w", "/en/static/aaed1826982279a08e13e2ac8311e597/b1001/WDMyCloud10_EN.png 1380w", "/en/static/aaed1826982279a08e13e2ac8311e597/cea38/WDMyCloud10_EN.png 1644w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/aaed1826982279a08e13e2ac8311e597/c0255/WDMyCloud10_EN.png",
              "alt": "Western Digital MyCloud as FTP Server",
              "title": "Western Digital MyCloud as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Step 10`}</strong>{`: Finally, we have to set up our camera by typing in all the FTP data. To do so, please open the web interface of your INSTAR camera. You can either type in the IP address of your camera in the address bar of your web browser, or you can use the INSTAR camera tool to search for your camera and to open it in the default web browser.`}</p>
    <p>{`Once it is opened, please click on „Alarm – FTP“ on the left menu bar to open the FTP settings.`}</p>
    <p><img parentName="p" {...{
        "src": "./WD_MyCloud_as_FTP_Server/WDMyCloud11_EN.png",
        "alt": "Western Digital MyCloud as FTP Server"
      }}></img></p>
    <p><strong parentName="p">{`Step 11`}</strong>{`: In the FTP mask please fill in the following data:`}</p>
    <ul>
      <li parentName="ul">{`FTP Server: Here you will need to type in the IP address of your WD MyCloud. As an alternative you can also type in wdmycloud.local`}</li>
      <li parentName="ul">{`FTP Port: Please leave it Port 21 which is used for FTP.`}</li>
      <li parentName="ul">{`FTP Folder: You will have to type in the folder name which you have created before as a shared Folder on your MyCloud. In our case it was the folder „Recordings“. If you are having more than one camera, we recommend you to also add another sub folder for each camera, in our case „Camera_LivingRoom“. This way it will be easier later to separate between the different cameras.`}</li>
      <li parentName="ul">{`FTP Username: The username of your WD MyCloud.`}</li>
      <li parentName="ul">{`FTP Password: The password of your WD MyCloud.`}</li>
      <li parentName="ul">{`FTP Mode: Please keep this PASV Mode.`}</li>
      <li parentName="ul">{`FTP Folder Structure: Here you can select if the camera should record everything in one single folder, or if the camera should create a new folder for each day. If the camera created a new folder for each day, each folder will be named according to the date. Therefore it will be much easier later to search for a recording from a particular day.`}</li>
    </ul>
    <p>{`Submit your settings and test them. If the test is successful, your camera should now be able to save the recordings on your WD MyCloud.`}</p>
    <p>{`Please don't forget to activate the motion detection (alarm areas) inside your camera and also make sure you activate the FTP upload under „Alarm – Actions“. Here you can select if the camera should Upload videos or images to the FTP server (WD MyCloud) in case of an alarm event. If you activate everything accordingly, the recordings should now be saved on your WD MyCloud.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      